import React from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import NavigationList from "../components/navigation-list";
import _ from "lodash"

class ProjectsIndex extends React.Component {
  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    const creationTypes = [
        'TUSCHE-ZEICHNUNG',
        'PERFORMANCE',
        'FOTOGRAFIE',
        'VIDEO',
        'INSTALLATION',
        'OBJEKT',
        'SCHREIBMASCHINENTEXT',
    ];
    const links = _.map(creationTypes, (type) => ({
      text: type.toUpperCase(),
      path: `/werk/${type.toLowerCase()}`
    }))

    return (
      <Layout location={this.props.location} showNavigation={true} path={[{text: 'Werke'}]}>
        <Helmet title={siteTitle}>
          <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
        </Helmet>
        <NavigationList
          title='WERKE'
          links={
            links
          }>
        </NavigationList>
      </Layout>
    )
  }
}

export default ProjectsIndex
